


import Vue from "vue"
export default Vue.extend({
	name: "ClientsPills",
	
	beforeMount() {
		this.mandatory = true;
		this.activeClass = "primary--text";
	},
	async mounted() {
		this.getSelectedClient()
	},
	data(): {
		componentKey: number
		clientDialog: boolean
		selectedItem: null | Record<string, any>
		loading: boolean
		mandatory: boolean
		activeClass: string
		selectedChip: string
	} {
		return {
			componentKey: 0,
			clientDialog: false,
			selectedItem: null,
			loading: false,
			mandatory: false,
			activeClass: "",
			selectedChip: ""
		}
	},
	computed: {
		clientsComplete(): Record<string, any>[] {
			return this.$vStore.state.context.associatedClients;
		},
	},
	methods: {
		/*
            Dont show pills if there is only 1 client.
        */
        atLeastTwo(): boolean {
            if (this.$store.state.context.associatedClients.length > 1) return true;
            return false;
        },
		async clientClick(item: Record<string, any>): Promise<void> {
			this.selectedItem = item
			await this.setSelectedClient()
		},
		showClientDialog(item: Record<string, any>): void {
			this.clientDialog = true
		},
		closeClientDialog(): void {
			this.clientDialog = false
		},
		async setSelectedClient(): Promise<void> {
			const hit = this.$vStore.state.context.associatedClients.find(client => client.id === this.selectedItem!.id)
			if(hit) await this.$vStore.dispatch("context/setClient", hit)
		},
		getSelectedClient(): void {
			if(this.$vStore.state.context.selectedClient){
				this.selectedChip = this.$vStore.state.context.selectedClient!.id;
			}
		},
	},
})
